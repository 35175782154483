<template lang="pug">
  .app-combobox
    v-combobox(
      :items="items"
      :value="value"
      :label="label"
      :disabled="disabled"
      name="name"
      clearable="clearable"
      closeOnContentClick
      closeOnClick
      :menu-props="{contentClass: 'menu-with-scroll-visible'}"
      @update:search-input="updateSearch"
      ref="select"
    ).app-combobox__wrapper
      <template v-slot:append v-if="value || value == 0">
        v-icon(color="primary") mdi-check-circle
      </template>
</template>

<script>
export default {
  name: 'appComboBox',

  props: {
    items: {
      type: Array,
      required: true
    },
    value: {
      required: true
    },
    showCloseMenuBtn: {
      default: true,
      type: Boolean
    },
    label: String,
    name: String,
    clearable: {
      default: true,
      type: Boolean
    },
    disabled: Boolean
  },

  data: () => ({
    searchValue: ''
  }),

  methods: {
    updateSearch(value) {
      this.searchValue = value
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss">
.app-combobox {
  position: relative;

  .v-input__slot {
    margin-bottom: 0;
  }

  .v-text-field__details {
    display: none;
  }
}

</style>
